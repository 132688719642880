<template>
  <div class="customerManagement-view bgfff">
    <div class="flex-a-b-c h56 pl24 pr24">
      <div class="flex-a-c felx1"> 
        <p>访问次数：</p>
        <el-input
          @keyup.native="startNumber" 
          maxlength="4" 
          placeholder="输入次数" 
          class="input-with-select w120 mr-16 d-flex align-items-center"
          v-model="form.startNumber"
        ></el-input>
        <p class="ml10 mr10">----</p>
        <el-input
          @keyup.native="endNumber"
          maxlength="4" 
          placeholder="输入次数"  
          class="mr-16 w120 d-flex align-items-center"
          v-model="form.endNumber"
        ></el-input>
        <el-date-picker
          class="w250 ml20 "
          v-model="value1"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-dropdown class="ml20 mr20" placement="bottom"  @command="typeSelection">
          <el-button>
            {{currentType}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in type" 
                              :key="index"
                              :command="item">{{item.title}}
                              
                              </el-dropdown-item>
          </el-dropdown-menu>

        </el-dropdown>
        <el-button>
          搜索
        </el-button>
        <el-button type="primary">
          导出
        </el-button>
      </div>
      <div class="flex-a-c"> 
        <el-button type="primary">
          录入客户
        </el-button>
      </div>
    </div>
    <commonTable :tableData="tableData">
      <template v-slot:table>
        <el-table-column type="selection" width="55" />
        <el-table-column prop="date" align="center" width="80" label="序号" />
        <el-table-column prop="name" align="center" label="姓名" />
        <el-table-column
          prop="iphone"
          align="center"
          label="手机号码"
          show-overflow-tooltip
        />
        <el-table-column prop="mailbox" align="center" label="邮箱" />
        <el-table-column prop="wechatNum" align="center" label="微信号" />
        <el-table-column prop="entryTime" align="center" label="录入时间" />
        <el-table-column prop="turnover" align="center" label="成交金额">
          <template slot-scope="scope"
            ><span style="color: #fe8575">{{
              scope.row.turnover
            }}</span></template
          >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <div class="operating">
            <el-button size="mini" type="text">详情</el-button>
            <el-button size="mini" type="text">编辑</el-button>
          </div>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from '@/components/common/commonTable';
export default {
  name: "customer-management",
  components:{
    commonTable
  },
  data() {
    return {
      activeName: "first",
      form: {
        startNumber: "", // 下限
        endNumber:''  //  上限
      },
      value1:"",
      currentType:'获客文章', // 当前type
      type:[
        {
          title:'获客文章'
        },
        {
          title:'海报'
        },
        {
          title:'分销'
        },
      ],
      tableData: [],
    };
  },
  
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
    startNumber(){
        this.form.startNumber= this.testNumber(this.form.startNumber)
    },
    endNumber(){
        this.form.endNumber= this.testNumber(this.form.endNumber)
    },
    testNumber(number){
      number = number.replace(/[^\.\d]/g,'');
      number = number.replace('.','');
      return number
    },
    typeSelection(val){ 
      this.currentType = val.title
      console.log(val)
    }
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type="number"] {
	-moz-appearance: textfield;
}
</style>